import React, { useEffect , useState }   from "react";
import { inject, observer } from 'mobx-react';

const Login = inject('store')(observer(({ store }) => {


  const [state, setState] = useState({
    submitted: false
  });
  
  useEffect(() => {
  store.authStore.removeAuthToken();
  store.authStore.setUser(null);


  }, [store]); // Empty dependency array ensures this runs only once on mount

  let handleSubmit = () => {
    console.log("submitted")
    store.authStore.setErrorMessage("")
    const promises = []
    promises.push(store.authStore.login())
    return Promise.all(promises).then(() =>
        setState({submitted:false}))
  }

  let handleUsername = (event) => {
    store.authStore.setUsername(event.target.value)
  }

  let handlePassword = (event) => {
    store.authStore.setPassword(event.target.value)
  }
  const errors = ''
  /*
  const errors = store.authStore.errors !== '' ? (
    <alert
        message={store.authStore.errors}
        type="error"
        showIcon
      />
  ) : "" ;

  */


  return (
    <div>
        <div className="auth-box">

      
        <h2>Login</h2>
        <p className="da-mt-sm-0 da-mt-8 da-text-color-black-60">
          Welcome, please login to your account.
        </p>
          { errors }
        <form
          layout="vertical"
          name="basic"
          initialvalues={{ remember: true }}
          className="da-mt-sm-16 da-mt-32"
        >
          <label label="Username :" className="lable" onChange={handleUsername}>
            Username
            <input className="input-login" id="username" placeholder="Username" />
          </label>
          
          

          <label label="Password :"  className="lable" onChange={handlePassword} >
            Password
            <input className="input-login" type="password" id="warning2" placeholder="Password" />
          </label>
             
         

          <label className="da-mt-16 da-mb-8">
            <button type="primary" htmltype="submit" 
              disabled={state.submitted 
                || !store.authStore.values.username 
                || !store.authStore.values.password
              }
              // icon={state.submitted ? true : null}
              onClick={() => {
              setState({submitted:true})
              handleSubmit()
              }}>
              Login
            </button>
          </label>

        </form>
        </div>
    </div>
  );
}));

export default Login;