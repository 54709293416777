
import React from "react";
import ReactQuill, { Quill } from "react-quill";
import htmlEditButton from "quill-html-edit-button";
import 'react-quill/dist/quill.snow.css';
Quill.register("modules/htmlEditButton", htmlEditButton);


function insertCustomTags(args) {

    //this.quill.format('H', false);
    const value = args;
    this.quill.insertText(0, `<span class="${value}"></span>`);

    //this.quill.insertText(cursorPosition, value);
   // this.quill.setSelection(cursorPosition + value.length);
  }



// Custom Toolbar component
const CustomToolbar = () => (
  <div id="toolbar">
    <select
      className="ql-header"
      defaultValue={""}
      onChange={(e) => e.persist()}
    >
      <option value="1" />
      <option value="2" />
      <option defaultValue />
    </select>
    <button className="ql-bold" />
    <button className="ql-italic" />

    <select className="ql-color">
      <option value="red" />
      <option value="green" />
      <option value="blue" />
      <option value="orange" />
      <option value="violet" />
      <option value="#d0d1d2" />
      <option defaultValue />
    </select>

    <select
      className="ql-insertCustomTags"
      defaultValue={""}
      onChange={(e) => e.persist()}
    >
      <option value="fi fi-af m-r">Afghanistan</option>
      <option value="fi fi-ax m-r">Aland Islands</option>
      <option value="fi fi-al m-r">Albania</option>
      <option value="fi fi-dz m-r">Algeria</option>
      <option value="fi fi-as m-r">American Samoa</option>
      <option value="fi fi-ad m-r">Andorra</option>
      <option value="fi fi-ao m-r">Angola</option>
      <option value="fi fi-ai m-r">Anguilla</option>
      <option value="fi fi-aq m-r">Antarctica</option>
      <option value="fi fi-ag m-r">Antigua and Barbuda</option>
      <option value="fi fi-ar m-r">Argentina</option>
      <option value="fi fi-am m-r">Armenia</option>
      <option value="fi fi-aw m-r">Aruba</option>
      <option value="fi fi-sh-ac m-r">Ascension Island</option>
      <option value="fi fi-au m-r">Australia</option>
      <option value="fi fi-at m-r">Austria</option>
      <option value="fi fi-az m-r">Azerbaijan</option>
      <option value="fi fi-bs m-r">Bahamas</option>
      <option value="fi fi-bh m-r">Bahrain</option>
      <option value="fi fi-bd m-r">Bangladesh</option>
      <option value="fi fi-bb m-r">Barbados</option>
      <option value="fi fi-es-pv m-r">Basque Country</option>
      <option value="fi fi-by m-r">Belarus</option>
      <option value="fi fi-be m-r">Belgium</option>
      <option value="fi fi-bz m-r">Belize</option>
      <option value="fi fi-bj m-r">Benin</option>
      <option value="fi fi-bm m-r">Bermuda</option>
      <option value="fi fi-bt m-r">Bhutan</option>
      <option value="fi fi-bo m-r">Bolivia</option>
      <option value="fi fi-bq m-r">Bonaire, Sint Eustatius and Saba</option>
      <option value="fi fi-ba m-r">Bosnia and Herzegovina</option>
      <option value="fi fi-bw m-r">Botswana</option>
      <option value="fi fi-bv m-r">Bouvet Island</option>
      <option value="fi fi-br m-r">Brazil</option>
      <option value="fi fi-io m-r">British Indian Ocean Territory</option>
      <option value="fi fi-bn m-r">Brunei Darussalam</option>
      <option value="fi fi-bg m-r">Bulgaria</option>
      <option value="fi fi-bf m-r">Burkina Faso</option>
      <option value="fi fi-bi m-r">Burundi</option>
      <option value="fi fi-cv m-r">Cabo Verde</option>
      <option value="fi fi-kh m-r">Cambodia</option>
      <option value="fi fi-cm m-r">Cameroon</option>
      <option value="fi fi-ca m-r">Canada</option>
      <option value="fi fi-ic m-r">Canary Islands</option>
      <option value="fi fi-es-ct m-r">Catalonia</option>
      <option value="fi fi-ky m-r">Cayman Islands</option>
      <option value="fi fi-cf m-r">Central African Republic</option>
      <option value="fi fi-cefta m-r">
        Central European Free Trade Agreement
      </option>
      <option value="fi fi-td m-r">Chad</option>
      <option value="fi fi-cl m-r">Chile</option>
      <option value="fi fi-cn m-r">China</option>
      <option value="fi fi-cx m-r">Christmas Island</option>
      <option value="fi fi-cp m-r">Clipperton Island</option>
      <option value="fi fi-cc m-r">Cocos (Keeling) Islands</option>
      <option value="fi fi-co m-r">Colombia</option>
      <option value="fi fi-km m-r">Comoros</option>
      <option value="fi fi-ck m-r">Cook Islands</option>
      <option value="fi fi-cr m-r">Costa Rica</option>
      <option value="fi fi-hr m-r">Croatia</option>
      <option value="fi fi-cu m-r">Cuba</option>
      <option value="fi fi-cw m-r">Curaçao</option>
      <option value="fi fi-cy m-r">Cyprus</option>
      <option value="fi fi-cz m-r">Czech Republic</option>
      <option value="fi fi-ci m-r">Côte d'Ivoire</option>
      <option value="fi fi-cd m-r">Democratic Republic of the Congo</option>
      <option value="fi fi-dk m-r">Denmark</option>
      <option value="fi fi-dg m-r">Diego Garcia</option>
      <option value="fi fi-dj m-r">Djibouti</option>
      <option value="fi fi-dm m-r">Dominica</option>
      <option value="fi fi-do m-r">Dominican Republic</option>
      <option value="fi fi-eac m-r">East African Community</option>
      <option value="fi fi-ec m-r">Ecuador</option>
      <option value="fi fi-eg m-r">Egypt</option>
      <option value="fi fi-sv m-r">El Salvador</option>
      <option value="fi fi-gb-eng m-r">England</option>
      <option value="fi fi-gq m-r">Equatorial Guinea</option>
      <option value="fi fi-er m-r">Eritrea</option>
      <option value="fi fi-ee m-r">Estonia</option>
      <option value="fi fi-sz m-r">Eswatini</option>
      <option value="fi fi-et m-r">Ethiopia</option>
      <option value="fi fi-eu m-r">Europe</option>
      <option value="fi fi-fk m-r">Falkland Islands</option>
      <option value="fi fi-fo m-r">Faroe Islands</option>
      <option value="fi fi-fm m-r">Federated States of Micronesia</option>
      <option value="fi fi-fj m-r">Fiji</option>
      <option value="fi fi-fi m-r">Finland</option>
      <option value="fi fi-fr m-r">France</option>
      <option value="fi fi-gf m-r">French Guiana</option>
      <option value="fi fi-pf m-r">French Polynesia</option>
      <option value="fi fi-tf m-r">French Southern Territories</option>
      <option value="fi fi-ga m-r">Gabon</option>
      <option value="fi fi-es-ga m-r">Galicia</option>
      <option value="fi fi-gm m-r">Gambia</option>
      <option value="fi fi-ge m-r">Georgia</option>
      <option value="fi fi-de m-r">Germany</option>
      <option value="fi fi-gh m-r">Ghana</option>
      <option value="fi fi-gi m-r">Gibraltar</option>
      <option value="fi fi-gr m-r">Greece</option>
      <option value="fi fi-gl m-r">Greenland</option>
      <option value="fi fi-gd m-r">Grenada</option>
      <option value="fi fi-gp m-r">Guadeloupe</option>
      <option value="fi fi-gu m-r">Guam</option>
      <option value="fi fi-gt m-r">Guatemala</option>
      <option value="fi fi-gg m-r">Guernsey</option>
      <option value="fi fi-gn m-r">Guinea</option>
      <option value="fi fi-gw m-r">Guinea-Bissau</option>
      <option value="fi fi-gy m-r">Guyana</option>
      <option value="fi fi-ht m-r">Haiti</option>
      <option value="fi fi-hm m-r">Heard Island and McDonald Islands</option>
      <option value="fi fi-va m-r">Holy See</option>
      <option value="fi fi-hn m-r">Honduras</option>
      <option value="fi fi-hk m-r">Hong Kong</option>
      <option value="fi fi-hu m-r">Hungary</option>
      <option value="fi fi-is m-r">Iceland</option>
      <option value="fi fi-in m-r">India</option>
      <option value="fi fi-id m-r">Indonesia</option>
      <option value="fi fi-ir m-r">Iran</option>
      <option value="fi fi-iq m-r">Iraq</option>
      <option value="fi fi-ie m-r">Ireland</option>
      <option value="fi fi-im m-r">Isle of Man</option>
      <option value="fi fi-il m-r">Israel</option>
      <option value="fi fi-it m-r">Italy</option>
      <option value="fi fi-jm m-r">Jamaica</option>
      <option value="fi fi-jp m-r">Japan</option>
      <option value="fi fi-je m-r">Jersey</option>
      <option value="fi fi-jo m-r">Jordan</option>
      <option value="fi fi-kz m-r">Kazakhstan</option>
      <option value="fi fi-ke m-r">Kenya</option>
      <option value="fi fi-ki m-r">Kiribati</option>
      <option value="fi fi-xk m-r">Kosovo</option>
      <option value="fi fi-kw m-r">Kuwait</option>
      <option value="fi fi-kg m-r">Kyrgyzstan</option>
      <option value="fi fi-la m-r">Laos</option>
      <option value="fi fi-lv m-r">Latvia</option>
      <option value="fi fi-arab m-r">League of Arab States</option>
      <option value="fi fi-lb m-r">Lebanon</option>
      <option value="fi fi-ls m-r">Lesotho</option>
      <option value="fi fi-lr m-r">Liberia</option>
      <option value="fi fi-ly m-r">Libya</option>
      <option value="fi fi-li m-r">Liechtenstein</option>
      <option value="fi fi-lt m-r">Lithuania</option>
      <option value="fi fi-lu m-r">Luxembourg</option>
      <option value="fi fi-mo m-r">Macau</option>
      <option value="fi fi-mg m-r">Madagascar</option>
      <option value="fi fi-mw m-r">Malawi</option>
      <option value="fi fi-my m-r">Malaysia</option>
      <option value="fi fi-mv m-r">Maldives</option>
      <option value="fi fi-ml m-r">Mali</option>
      <option value="fi fi-mt m-r">Malta</option>
      <option value="fi fi-mh m-r">Marshall Islands</option>
      <option value="fi fi-mq m-r">Martinique</option>
      <option value="fi fi-mr m-r">Mauritania</option>
      <option value="fi fi-mu m-r">Mauritius</option>
      <option value="fi fi-yt m-r">Mayotte</option>
      <option value="fi fi-mx m-r">Mexico</option>
      <option value="fi fi-md m-r">Moldova</option>
      <option value="fi fi-mc m-r">Monaco</option>
      <option value="fi fi-mn m-r">Mongolia</option>
      <option value="fi fi-me m-r">Montenegro</option>
      <option value="fi fi-ms m-r">Montserrat</option>
      <option value="fi fi-ma m-r">Morocco</option>
      <option value="fi fi-mz m-r">Mozambique</option>
      <option value="fi fi-mm m-r">Myanmar</option>
      <option value="fi fi-na m-r">Namibia</option>
      <option value="fi fi-nr m-r">Nauru</option>
      <option value="fi fi-np m-r">Nepal</option>
      <option value="fi fi-nl m-r">Netherlands</option>
      <option value="fi fi-nc m-r">New Caledonia</option>
      <option value="fi fi-nz m-r">New Zealand</option>
      <option value="fi fi-ni m-r">Nicaragua</option>
      <option value="fi fi-ne m-r">Niger</option>
      <option value="fi fi-ng m-r">Nigeria</option>
      <option value="fi fi-nu m-r">Niue</option>
      <option value="fi fi-nf m-r">Norfolk Island</option>
      <option value="fi fi-kp m-r">North Korea</option>
      <option value="fi fi-mk m-r">North Macedonia</option>
      <option value="fi fi-gb-nir m-r">Northern Ireland</option>
      <option value="fi fi-mp m-r">Northern Mariana Islands</option>
      <option value="fi fi-no m-r">Norway</option>
      <option value="fi fi-om m-r">Oman</option>
      <option value="fi fi-pc m-r">Pacific Community</option>
      <option value="fi fi-pk m-r">Pakistan</option>
      <option value="fi fi-pw m-r">Palau</option>
      <option value="fi fi-pa m-r">Panama</option>
      <option value="fi fi-pg m-r">Papua New Guinea</option>
      <option value="fi fi-py m-r">Paraguay</option>
      <option value="fi fi-pe m-r">Peru</option>
      <option value="fi fi-ph m-r">Philippines</option>
      <option value="fi fi-pn m-r">Pitcairn</option>
      <option value="fi fi-pl m-r">Poland</option>
      <option value="fi fi-pt m-r">Portugal</option>
      <option value="fi fi-pr m-r">Puerto Rico</option>
      <option value="fi fi-qa m-r">Qatar</option>
      <option value="fi fi-cg m-r">Republic of the Congo</option>
      <option value="fi fi-ro m-r">Romania</option>
      <option value="fi fi-ru m-r">Russia</option>
      <option value="fi fi-rw m-r">Rwanda</option>
      <option value="fi fi-re m-r">Réunion</option>
      <option value="fi fi-bl m-r">Saint Barthélemy</option>
      <option value="fi fi-sh-hl m-r">Saint Helena</option>
      <option value="fi fi-sh m-r">
        Saint Helena, Ascension and Tristan da Cunha
      </option>
      <option value="fi fi-kn m-r">Saint Kitts and Nevis</option>
      <option value="fi fi-lc m-r">Saint Lucia</option>
      <option value="fi fi-mf m-r">Saint Martin</option>
      <option value="fi fi-pm m-r">Saint Pierre and Miquelon</option>
      <option value="fi fi-vc m-r">Saint Vincent and the Grenadines</option>
      <option value="fi fi-ws m-r">Samoa</option>
      <option value="fi fi-sm m-r">San Marino</option>
      <option value="fi fi-st m-r">Sao Tome and Principe</option>
      <option value="fi fi-sa m-r">Saudi Arabia</option>
      <option value="fi fi-gb-sct m-r">Scotland</option>
      <option value="fi fi-sn m-r">Senegal</option>
      <option value="fi fi-rs m-r">Serbia</option>
      <option value="fi fi-sc m-r">Seychelles</option>
      <option value="fi fi-sl m-r">Sierra Leone</option>
      <option value="fi fi-sg m-r">Singapore</option>
      <option value="fi fi-sx m-r">Sint Maarten</option>
      <option value="fi fi-sk m-r">Slovakia</option>
      <option value="fi fi-si m-r">Slovenia</option>
      <option value="fi fi-sb m-r">Solomon Islands</option>
      <option value="fi fi-so m-r">Somalia</option>
      <option value="fi fi-za m-r">South Africa</option>
      <option value="fi fi-gs m-r">
        South Georgia and the South Sandwich Islands
      </option>
      <option value="fi fi-kr m-r">South Korea</option>
      <option value="fi fi-ss m-r">South Sudan</option>
      <option value="fi fi-es m-r">Spain</option>
      <option value="fi fi-lk m-r">Sri Lanka</option>
      <option value="fi fi-ps m-r">State of Palestine</option>
      <option value="fi fi-sd m-r">Sudan</option>
      <option value="fi fi-sr m-r">Suriname</option>
      <option value="fi fi-sj m-r">Svalbard and Jan Mayen</option>
      <option value="fi fi-se m-r">Sweden</option>
      <option value="fi fi-ch m-r">Switzerland</option>
      <option value="fi fi-sy m-r">Syria</option>
      <option value="fi fi-tw m-r">Taiwan</option>
      <option value="fi fi-tj m-r">Tajikistan</option>
      <option value="fi fi-tz m-r">Tanzania</option>
      <option value="fi fi-th m-r">Thailand</option>
      <option value="fi fi-tl m-r">Timor-Leste</option>
      <option value="fi fi-tg m-r">Togo</option>
      <option value="fi fi-tk m-r">Tokelau</option>
      <option value="fi fi-to m-r">Tonga</option>
      <option value="fi fi-tt m-r">Trinidad and Tobago</option>
      <option value="fi fi-sh-ta m-r">Tristan da Cunha</option>
      <option value="fi fi-tn m-r">Tunisia</option>
      <option value="fi fi-tm m-r">Turkmenistan</option>
      <option value="fi fi-tc m-r">Turks and Caicos Islands</option>
      <option value="fi fi-tv m-r">Tuvalu</option>
      <option value="fi fi-tr m-r">Türkiye</option>
      <option value="fi fi-ug m-r">Uganda</option>
      <option value="fi fi-ua m-r">Ukraine</option>
      <option value="fi fi-ae m-r">United Arab Emirates</option>
      <option value="fi fi-gb m-r">United Kingdom</option>
      <option value="fi fi-un m-r">United Nations</option>
      <option value="fi fi-um m-r">United States Minor Outlying Islands</option>
      <option value="fi fi-us m-r">United States of America</option>
      <option value="fi fi-xx m-r">Unknown</option>
      <option value="fi fi-uy m-r">Uruguay</option>
      <option value="fi fi-uz m-r">Uzbekistan</option>
      <option value="fi fi-vu m-r">Vanuatu</option>
      <option value="fi fi-ve m-r">Venezuela</option>
      <option value="fi fi-vn m-r">Vietnam</option>
      <option value="fi fi-vg m-r">Virgin Islands (British)</option>
      <option value="fi fi-vi m-r">Virgin Islands (U.S.)</option>
      <option value="fi fi-gb-wls m-r">Wales</option>
      <option value="fi fi-wf m-r">Wallis and Futuna</option>
      <option value="fi fi-eh m-r">Western Sahara</option>
      <option value="fi fi-ye m-r">Yemen</option>
      <option value="fi fi-zm m-r">Zambia</option>
      <option value="fi fi-zw m-r">Zimbabwe</option>
      <option value="fi fi-nato m-r">Nato</option>
    </select>
  </div>
);

// Editor component
const Editor = ({ value, onChange }) => {

  return (
  <div className="text-editor">
      <CustomToolbar />
      <ReactQuill
           value={value}
           onChange={onChange}
        //placeholder={placeholder}
        modules={Editor.modules}
        formats={Editor.formats}
        theme="snow"
      />
      </div>
  );

};

// Quill modules to attach to editor
Editor.modules = {
  htmlEditButton: {

  },
  toolbar: {
    
    container: "#toolbar",
    handlers: {

      insertCustomTags:insertCustomTags
    }
  },
  clipboard: {
    matchVisual: false,
  }


};

// Quill editor formats
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",

  "insertCustomTags"
];

// PropType validation


export default Editor;
